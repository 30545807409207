<template>
  <div class="bg">
    <div class="header">
      <div class="headerL">
        <router-link to="/">
          <img src="@/assets/image/logo.png" alt="logo" />
        </router-link>
      </div>
      <div class="title">外研教学评系统用户协议</div>
    </div>
    <div class="main">
      <p>发布时间：2020 年 11 月 24 日</p>
      <br />
      <p>尊敬的用户：</p>
      <p>
        外研教学评网站（简称“本网站”）的各项电子服务的所有权和运作权等相关权利归属于外语教学与研究出版社有限责任公司（简称“外研社”）。
      </p>
      <p>
        本《用户协议》构成您与外研社之间就您使用本网站达成的具有法律约束力的完整协议。除非您接受本协议全部条款，否则您无权注册、登录或使用本协议所涉及的相关服务。
      </p>
      <br />
      <div class="weight">一、服务条款的确认和接受</div>
      <p>
        本网站在此特别提醒您：在您使用本网站服务前，请务必确实仔细阅读本《用户协议》（简称本协议）。如果您对本协议的任何条款及/或将来随时修改、补充的条款有异议，您可以选择不使用本网站提供的服务；当您点选“同意”按钮，或定制、使用、接受本网站服务时，即视为您已仔细阅读并同意接受本协议全部条款，包括接受本网站对本协议条款经不时所做的任何修改，并愿受其拘束。您直接或通过各类方式（包括但不限于站外API、RSS引用、借用、盗用其他用户账号等）间接使用本网站服务的行为，均将被视为已无条件接受本协议全部条款。
      </p>
      <div class="weight">二、用户的权利和义务</div>
      <p>
        您可浏览本网站的信息，您需先登录您的帐号，或注册手机帐号并登录。如您选用其他第三方帐号登录本网站的，您应保证第三方帐号的稳定性、真实性以及可用性，如因第三方帐号原因（如第三方帐号被封号）致使您无法登录本网站的，
        您应与第三方帐号的所属公司联系。您登录的帐号是本网站确认您身份的唯一依据。
      </p>
      <p>包括您在内的所有用户必须遵循：</p>
      <ul>
        <li>遵守所有使用网络服务的网络协议、规定、程序和惯例；</li>
        <li>不得使用本网站从事违法活动；</li>
        <li>不得干扰或侵犯本网站的正常运行和其他用户的正常使用；</li>
        <li>不得做出其他法律法规限制或禁止的行为；</li>
      </ul>
      <p>同时您应承诺：</p>
      <ul>
        <li>
          不得传输任何非法的、骚扰性的、中伤或诽谤他人的、辱骂性的、恐吓性的、伤害性的、
          庸俗的、淫秽、赌博、暴力、凶杀或者教唆犯罪的信息；
        </li>
        <li>不得传输任何教唆他人进行违法犯罪行为的资料；</li>
        <li>
          不得传输任危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一、不利于国内团
          结和社会安定的资料；
        </li>
        <li>
          不得传输任何不符合国家法律、法规规定，或损害国家荣誉和利益的，损害公共利益的资
          料、信息；
        </li>
        <li>
          不得以任何形式使用本网站服务进行任何不利于本网站的行为或侵犯本网站的商业利益，
          包括但不限于发布未经本网站许可的商业广告；
        </li>
        <li>
          用户上传的任何作品或内容，不得侵犯任何第三方的著作权等知识产权；
        </li>
      </ul>
      <p>
        如果用户的行为违背上述两款中的任一规定，本网站可以做出独立判断并有权立即取消
        用户资格。用户应对自己在本网站上的违法行为承担全部法律责任。
      </p>
      <p>基于网络服务的特殊性，您同意：</p>
      <ul>
        <li>在注册时按照注册提示提供真实、详尽的个人资料；</li>
        <li>
          在个人的注册信息发生变化时，用户应及时更新自己的注册信息，保证其个人资料的详尽、
          真实和准确。所有用户输入的个人信息将被视作准确表明了用户的身份，并作为本网站提供
          所有服务的有效身份依据。
        </li>
        <li>
          因用户未提供真实个人资料或未及时更新注册信息，从而可能导致注册失败或不能完全使
          用本网站的全部功能，本网站对此不承担任何责任。
        </li>
        <li>用户自行配备上网的所需设备，包括个人电脑及上网装置；</li>
        <li>
          用户应自行负担因使用这种接入方式而产生的上网电话费、上网信息费及教育信息费。
        </li>
      </ul>
      <p>用户的用户名、密码：</p>
      <ul>
        <li>
          用户一旦注册成功成为本网站的正式用户后，应当对自己的用户名（或称帐号）与密码的
          安全性负全部责任。您要注意保管好帐号及密码，不应将您的帐号、密码转让或出借给他人
          使用。如因您未保管好自己的帐号和密码而对自己、外研社或第三方造成损害的，您将自负
          全部责任。另外，您应对您帐号中的所有活动和事件负全责。因黑客行为或您保管疏忽导致
          账号、密码遭他人非法使用，外研社不承担任何责任。
        </li>
        <li>
          对于您因帐号或密码泄露造成的各种损失，本网站不承担任何责任。您发现任何非法使用
          您帐号的情况，应立即通知本网站。
        </li>
        <li>
          您应对以其用户名（包括但不限于自行使用、借用或被盗用等情况）所进行的所有活动和
          事件负责。
        </li>
        <li>
          如果您登录、使用帐号头像、个人简介等帐号信息资料存在违法和不良信息的，外研社有权
          采取通知限期改正、暂停使用等措施。对于冒用关联机构或社会名人登录、使用、填写帐号名
          称、头像、个人简介的，外研社有权冻结或删除该账号，并通告有关机构或个人。
        </li>

        <li>
          您同意外研社以以下合理的方式向您送达各类通知，包括但不限于站内消息、弹出消息、客户端推
          送的消息、根据您预留于我方平台的联系方式发出的电子邮件、手机短信、函件等。
        </li>
        <li>
          您的用户名不得转让、出借、售卖，一经发现，本网站有权取消您的用户资格，并停止对
          您的用户名的全部服务。
        </li>
      </ul>
      <div class="weight">三、外研社的权利和义务</div>
      <p>
        外研社不断创新以向您提供最优体验。您认知并同意并理解，外研社提供服务的形式和
        本质可不经事先通知您而变换。
      </p>
      <p>
        外研社需要定期或不定期地对提供服务的平台或相关的设备进行检修或者维护，本网站
        将尽可能事先进行通告。因此类情况而造成服务的中断，您应予以理解。外研社对此不承担
        任何责任但应尽最大努力尽快恢复服务。
      </p>
      <p>
        您认知并同意，外研社可自行决定，无须事先通知您，即有权停止（永久或暂时）向您
        或全体用户提供服务。
      </p>
      <p>外研社保留在本网站中投放商业性广告的权利。</p>
      <p>
        本网站发布、提交的由其他第三方提供的内容，其合法性由作品提供者负责。本网站不
        对作品本身的合法性、有效性、实用性、准确性等提供任何保证，并不承担任何法律责任。
        外研社为提供网络服务而使用的任何软件（包括但不限于软件中所含的任何图象、照片、动
        画、录像、录音、音乐、文字和附加程序、随附的帮助材料）的一切权利均属于该软件的著
        作权人，未经该软件的著作权人许可，您不得对该软件进行反向工程（reverse
        engineer）、 反向编译（decompile）或反汇编（disassemble）。
      </p>
      <p>
        本网站内发布的以任何形式表现的作品、信息、资料（包括但不限于文字、软件、声音、
        图片、视频、表格、电子邮件等），您未经许可，不得擅自对本网站的任何内容进行任何形
        式的翻录、复制或从事其他任何违反著作权法等相关法律、法规的活动。对侵犯本网站作品
        知识产权及/或其他个人和组织的合法权益的行为，外研社将依法追究其民事、行政或刑事
        责任。本协议已经构成《著作权法》第二十七条所规定的书面合同，其效力及于用户在本网
        站发布的任何受著作权法保护的作品内容。
      </p>
      <p>
        本网站有权将站内语料文本用于学术研究（文章附带版权声明者除外），收集时文本中
        的人名等个人信息作匿名处理。
      </p>
      <p>
        本网站享有对用户网上活动的监督和指导权，对凡是从事网上非法活动的用户，有权删
        除涉嫌侵权的作品、信息或内容，取消用户资格，直至终止所有服务。对于违反有关法律法
        规、侵犯他人隐私和名誉等内容，本网站有保存相关记录，并依法向有关部门提供的权利。
      </p>
      <p>
        对于您上传到本网站上可公开获取区域的任何内容，您同意外研社在全世界范围内具有
        免费的、永久性的、不可撤销的、非独家的和完全再许可的权利和许可，以使用、复制、修
        改、改编、出版、翻译、据以创作衍生作品、传播、表演和展示此等内容（整体或部分），
        和/或将此等内容编入当前已知的或以后开发的其他任何形式的作品、媒体或技术中。
        本网站享有将您的相关数据用做分析、科研数据支持的权利，以及享有定期对您发送相关邮
        件的权利。
      </p>
      <p>
        收费项目都有自己单独的服务有效期，您一旦购买此项收费服务即视为认可它的服务有
        效期。每项收费服务的服务有效期以此服务旁边的标注期限为准。如您未在有效时间内享用
        已购买的服务，视为您已全部享用，本网站及相关第三方均概不退款。
      </p>
      <p>
        您理解并认可，如果您通过第三方支付工具在我方平台账户充值后可能产生的任何商业
        风险（包括但不限于不法分子利用您账户或银行卡等有价卡等进行违法活动，第三方代为充
        值后再退款导致您的会员权益无法实现等），该等风险均有可能给您造成相应的经济损失，
        外研社在充分履行其在本协议项下义务和符合法律规定的前提下，在法律有明确规定的情况
        和范围内，承担相应的责任。
      </p>
      <div class="weight">四、免责声明</div>
      <p>
        本网站所有信息仅供参考使用，本网站不对任何信息的绝对准确性和完整性负责。本网
        站对任何由于使用本网站任何信息而引起的损失不承担任何责任。
      </p>
      <p>
        由您自愿上传的任何作品或内容，仅代表该您的立场和观点，并由您对其合法性承担责
        任，本网站不对任何侵权内容承担任何责任。
      </p>
      <p>
        本网站上链接的非本站的网页和内容不受本网站的控制，本网站对任何链接的网页或内
        容不承担任何责任。
      </p>
      <p>
        对不可抗力导致的损失不承担责任。本协议所指不可抗力包括：天灾、法律法规或政府
        指令的变更，因网络服务特性而特有的原因，例如境内外基础电信运营商的故障、计算机或
        互联网相关技术缺陷、互联网覆盖范围限制、计算机病毒、黑客攻击等因素，及其他合法范
        围内的不能预见、不能避免并不能克服的客观情况。
      </p>

      <div class="weight">五、隐私</div>
      <p>
        您在使用本网站提供的服务过程中，为了保证本网站服务的正常运行，可能需要收集您
        的一些信息，包括但不限于个人注册信息、本网站自动接收并记录的用户浏览器上的服务器
        数值、IP
        地址等数据及用户要求取用的网页记录等，您同意并授权外研社基于本网站运行
        的必要性获取并使用您的用户信息。
      </p>
      <p>
        外研社可能会统计您对本网站的使用情况，并于公众分享这些统计信息，以展示我们服
        务的整体使用趋势，这些统计信息不包含您的任何身份识别信息。
      </p>
      <p>
        外研社不会向任何人出售或出借您的个人信息，除非事先得到您的许可或在使用本网站
        服务的过程中，您主动向第三方分享的信息。
      </p>
      <p>
        外研社不会披露您的个人信息，但以下情况除外：（1）事先征得您的同意；（2）根据法
        律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；（3）如果
        用户出现违反中国有关法律或者网站政策的情况，需要向第三方披露；（4）为提供用户所要
        求的产品和服务，而必须和第三方分享用户的个人信息；（5）其它外研社根据法律或者网站
        政策认为合适的披露。
      </p>
      <p>
        需要特别说明的是，本隐私权政策不适用于其他第三方向您提供的服务，例如本网站上
        的第三方依托本网站向您提供服务时（如第三方支付渠道），您向第三方提供的个人信息不
        适用于本隐私权政策，除非法律有明确规定，外研社对任何第三方使用由您提供的信息不承
        担任何责任。
      </p>

      <div class="weight">六、保密责任</div>
      <p>
        本网站将严格履行用户个人隐私保密义务，承诺不公开、编辑或透露用户个人信息，但
        以下情况除外：
      </p>
      <ul>
        <li>您授权本网站透露这些信息；</li>
        <li>相应的法律及法律程序要求外研社提供您的个人资料；</li>
        <li>
          在紧急情况下，竭力维护您个人、其他社会个体和社会大众的安全需要。
        </li>
      </ul>
      <div class="weight">七、责任限制</div>
      <p>
        在适用法律允许的最大范围内，外研社不就因您使用本网站引起的，或在任何方面与外
        研社的产品和服务有关的任何意外的、非直接的、特殊的、或间接的损害或请求（包括但不
        限于因人身伤害、因隐私泄漏、因未能履行包括诚信或合理谨慎在内的任何责任、因过失和
        因任何其他金钱上的损失或其他损失而造成的损害赔偿）承担任何责任。
      </p>
      <div class="weight">八、服务变更</div>
      <p>
        鉴于网络服务的特殊性，您同意外研社有权随时变更、中断或终止部分或全部的网络服
        务（包括收费网络服务及免费网络服务）。如变更、中断或终止的网络服务属于免费网络服
        务，外研社无需通知您，也无需对您或任何第三方承担任何责任；如变更、中断或终止的网
        络服务属于收费网络服务，外研社应当在变更、中断或终止之前事先通知您，并应向受影响
        的用户提供等值的替代性的收费网络服务，如您不愿意接受替代性的收费网络服务，就您已
        经向外研社支付的服务费，外研社应当按照您实际使用相应收费网络服务的情况扣除相应服
        务费之后将剩余的服务费退还给您。
      </p>

      <div class="weight">九、用户协议的修改和完善</div>
      <p>
        本网站将根据互连网的发展和法律、法规的变化，在认为必要时，有权单方面修改本协
        议条款。外研社保留随时更改本网站和本协议的权利。
      </p>
      <p>
        本协议一旦发生变动，将会在本网站上提示修改内容。您应当不时予以查看并了解相关
        变更内容。如果您不同意所改动的内容，您可以主动放弃获得的网络服务。如果您继续享用
        网络服务，则视为接受本协议的变动。当发生争执时，应以修改后的最新《用户协议》的内
        容为准。
      </p>
      <div class="weight">十、违约责任</div>
      <p>
        您同意保障和维护外研社的利益，承担由您违反本协议和/或其他服务条款引起的本网
        站的全部损失，包括但不限于律师费用、损害补偿费用、政府机关处罚费用和其它侵权赔偿
        费用等。
      </p>

      <div class="weight">十一、法律适用及争议解决</div>
      <p>
        本协议根据现行中华人民共和国法律法规制定和解释。您与本网站因本协议产生争议时，
        应友好协商解决。协商不成的，双方有权向外研社所在地有管辖权的人民法院提起诉讼。
      </p>
      <div class="weight">十二、其他</div>
      <p>本协议的最终解释权由外研社行使。</p>
    </div>
  </div>
</template>
<style scoped>
p {
  text-indent: 2rem;
}
.header {
  background-color: #47475c;
  color: #ffffff;
  overflow: hidden;
}
.headerL {
  float: left;
  color: #ffffff;
  overflow: hidden;
}
.headerL img {
  height: 3.2rem;
  padding: 0.3rem;
  margin-left: 0.5rem;
}
.title {
  font-size: 2rem;
  text-align: center;
  line-height: 60px;
  width: 800px;
  margin: 0 auto;
}
.bg {
  background-color: #eee;
}
.main {
  padding: 1.786em 2.5em;
  background-color: #fff;
  max-width: 1000px;
  margin: 2rem auto;
  line-height: 2.2em;
  margin-bottom: 0;
}
.h1 {
  font-weight: 500;
  font-size: 1.5rem;
  padding: 1.5rem 0;
}
.weight {
  font-weight: 600;
}
ul {
  list-style-type: disc;
  padding-left: 3rem;
}
.pl1 {
  padding-left: 1rem;
}
.underL {
  text-decoration: underline;
}
</style>
